<template>
  <Page :title="$t('report.title_$year', { year })" :classes="['page--entity']" :buttons="buttons" @onEdit="editReport">

    <ReportHeaderComponent :entity="entity" :report="report" />

    <EntityPriorityODSComponent
      v-if="report"
      :report="report"></EntityPriorityODSComponent>

    <BlockSubtitle modal="evolution">{{$t('common.evolution_chart')}}</BlockSubtitle>
    <ChartSlices
      v-if="report"
      :oncreated="true"
      :results="entityChallenges"
      :total="1"></ChartSlices>

    <EntityPhasesComponent
      v-if="report && entity.id"
      :report="report"></EntityPhasesComponent>

  </Page>
</template>

<script>
import { mapGetters } from 'vuex';
import EntityPriorityODSComponent from '@/components/entities/priorityods/EntityPriorityODSComponent.vue';
import ChartSlices from '@/components/charts/ChartSlices.vue';
import EntityPhasesComponent from '@/components/entities/phases/EntityPhasesComponent.vue';
import ReportHeaderComponent from './components/ReportHeaderComponent.vue';

export default {
  name: 'ReportPage',
  components: {
    EntityPriorityODSComponent,
    ChartSlices,
    EntityPhasesComponent,
    ReportHeaderComponent,
  },
  mounted() {
    if (!this.entity.id) this.retrieveEntity();
    if (this.report.year !== +this.$route.params.year) this.retrieveReport(+this.$route.params.year);
  },
  methods: {
    userCanEditReport() {
      return this.self?.id && (this.self.is_staff || this.entity?.created_by === this.self?.id);
    },
    retrieveReport(year) {
      this.$store.dispatch('list', {
        model: 'report',
        params: { entity: this.self.entities[0].id, year },
      }).then((response) => {
        if (response.data?.count) {
          this.$store.dispatch('updateModel', { model: 'report', data: response.data.results[0] });
        } else {
          this.$store.dispatch('emptyModel', 'report');
        }
      }).catch((e) => {
        console.error(e);
        this.$store.dispatch('meeseeks', {
          message: this.$t('error.cant_retrieve_data'),
          type: 'error',
        });
      });
    },
    retrieveEntity() {
      this.$store.dispatch('retrieve', {
        model: 'entity',
        id: this.self.entities[0].id,
        updateModel: true,
      }).catch((e) => {
        console.error(e);
        this.$store.dispatch('meeseeks', {
          message: this.$t('error.cant_retrieve_data'),
          type: 'error',
        });
      });
    },
    editReport() {
      this.$router.push({ name: 'edit-report', params: { locale: this.$i18n.locale, year: this.report.year } });
    },
  },
  computed: {
    ...mapGetters(['self', 'report', 'entity']),
    buttons() {
      if (this.userCanEditReport()) return ['edit'];
      return [];
    },
    entityChallenges() {
      return this.report.challenges
        .reduce((acc, curr) => {
          acc[curr.challenge] = 1;
          return acc;
        }, {});
    },
    year() {
      return this.report.year;
    },
  },
  watch: {
    $route(route) {
      if (route.params?.year) {
        this.retrieveReport(+route.params.year);
      }
    },
  },
};
</script>
